<template>
    <div class="center">
      <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 30 30">
        <image href="/Images/Ok.png" width="30" height="30"/>
      </svg>
      <div class="center">
        <h3>Subscription to plan successful!</h3>
      </div>
      <div class="center">
        <form action="/create-portal-session" method="POST">
          <button id="checkout-and-portal-button" type="submit">Manage your billing information</button>
        </form>
      </div>
    </div>

</template>

<script>
export default {
  name: "App",
  data() {
    return {
      email: null,
      username: null,
      sessionId: "", // Initialize as empty
    };
  },
  mounted() {
    // Example: Set sessionId dynamically when the component is mounted
    this.sessionId = this.getSessionIdFromURL() || "ERROR?"; // Replace with actual logic
  },
  getSessionIdFromURL() {
      const params = new URLSearchParams(window.location.search);
      return params.get("session_id"); // Return the session_id value
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.email == null) {
        vm.$router.push({ name: "login" });
      } else {
        vm.email = vm.$store.state.email;
      }
    });
  },
};
</script>

<style scoped>
.center {
  text-align: center;
  vertical-align: middle;
  line-height: 12;
  padding: 2rem;
}
</style>
