<template>
  <div>
    <div class="itemDescription">
      <img v-if="item.Tipo == 3" class="shop-item-image" :src="`/Images/${item.Nombre}.gif`" />
      <div class="itemDescriptionText">
        <div class="topRow">
          {{ item.Coste / 100 }} €
          <div />
          <button class="closebutton" @click="show">X</button>
        </div>
        <br />
        <div class="secRow">
          {{ item.Nombre }}
          <a class="vendorname" :href="item.Sitio">{{ item.Empresa }}</a>
        </div>
        <div v-show="item.Tipo == 3">
          <br />
          INCLUDES: <br />
          - Animated in game skin <br />
          - Printable STL file
          <br />
        </div>
        <br />
        {{ item.Info }}
        <br /><br />
        <div v-if="item.Tipo == 4">
          <emailverification @userExists="user1 = $event"/>
          <emailverification @userExists="user2 = $event"/>
          <emailverification @userExists="user3 = $event"/>
          <emailverification @userExists="user4 = $event"/>
          <emailverification @userExists="user5 = $event"/>
          <emailverification @userExists="user6 = $event"/>
          <emailverification @userExists="user7 = $event"/>
          <emailverification @userExists="user8 = $event"/>
          <textarea class="license" v-model="item.Licencia"> </textarea>
          <div>
            <label class="centerHorizontally" for="agree">
              <input class="centerHorizontally" id="agree" type="checkbox" value="agree" v-model="checked" />
              I have read the terms and I accept them.
            </label>
            <button
              ref="scrollToMe"
              :disabled="!allok"
              @click="addToCart(item, true)"
              class="btn shop-item-button"
              :class="{ 'btn-danger': added, 'btn-friendly': !buyable, 'btn-primary': buyable }"
              type="button"
            >
            {{ ownedtext }}
            </button>
          </div>
        </div>
        <div v-else>

            <div v-if="item.Tipo == 1" class="centerHorizontally">
              <router-link :to="{ name: 'redeemcode' }"> Redeem code </router-link>
            </div>

            
            <label class="centerHorizontally" for="agree">
              <input class="centerHorizontally" id="agree" type="checkbox" value="agree" v-model="isGift" />
              This is a gift
            </label>
            <div v-if="item.Tipo == 1">
              <div v-if="isGift">
                <emailverification :isGift="true" @userExists="user1 = $event"/>
              </div>
            </div>
            <div v-else>
              <div v-if="isGift">
                <emailverification :isGift="true" :itemID="item.ID" @userExists="user1 = $event"/>
              </div>
            </div>
            <textarea class="license" v-model="item.Licencia"> </textarea>
            <label class="centerHorizontally" for="agree">
              <input class="centerHorizontally" id="agree" type="checkbox" value="agree" v-model="checked" />
              I have read the terms and I accept them.
            </label>
            <div v-if="item.Tipo != 1 || isGift">
                <button
                ref="scrollToMe"
                :disabled="!isok"
                @click="addToCart(item)"
                class="btn shop-item-button"
                :class="{ 'btn-danger': added, 'btn-friendly': !buyable && !isGift, 'btn-primary': buyable }"
                type="button"
              >
              {{ ownedtext }}
              </button>
            </div>
            <div v-else>
              <button
                ref="scrollToMe"
                :disabled="!isok"
                @click="buySubscription(item)"
                class="btn shop-item-button"
                :class="{ 'btn-danger': added, 'btn-friendly': !buyable && !isGift, 'btn-primary': buyable }"
                type="button"
              >
              Subscribe Now
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { responseErrorHandling } from "@/assets/js/common.js";
import { isInCart } from "@/store/index.js";
import emailverification from './emailverification.vue';

export default {
  components: { emailverification },
  data() {
    return {
      hidden: true,
      items: null,
      checked: false,
      isGift: false,
      user1: '',
      user2: '',
      user3: '',
      user4: '',
      user5: '',
      user6: '',
      user7: '',
      user8: '',
    };
  },

  props: ["item"],

  mounted() {
    this.getPurchaseHistory();
  },

  computed: {
    isok()
    {
      if (this.isGift)
      {
        return this.checked && this.user1 != '';
      }
      return this.checked;
    },

    allok()
    {
      return this.checked && 
            this.user1 != '' && this.user2 != '' && this.user3 != ''
            && this.user4 != '' && this.user5 != '' && this.user6 != ''
            && this.user7 != '' && this.user8 != '';
    },

    added() {
      return isInCart(this.$store.state, this.item);
    },

    buyable() {
      if (this.$store.state.ownedDLCS.includes(this.item.Nombre)) {
        return false;
      } else {
        return true;
      }
    },

    ownedtext() {
      if (this.added) { return "REMOVE"; }
      else if (this.isGift) { return "GIFT";  }
      else if (this.buyable) { return "ADD TO CART"; }
      else { return "OWNED"; }
    },
  },

  methods: {
    addToCart(elt) {
      if (this.buyable) {
        if (this.added) {
          this.$store.commit("removeFromCart", this.item);
        } else {
          window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
          this.$store.commit("addToCart", elt);
        }
      }
    },

    buySubscription(elt) {
      if (this.buyable) {
        this.initSubscription(elt.ID);
      }
    },

    async initSubscription(ID) {
      // Disable the button until we have Stripe set up on the page
      let res = await fetch("/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        //TODO: Should be this only subscription item
        body: JSON.stringify({ items: ID }),
      });
      const { url } = await res.json();

      window.open(url, '_blank');
    },

    addToCart(elt, hasUsernames) {
      elt.users = {};
      elt.users.user1 = this.user1;
      elt.users.user2 = this.user2;
      elt.users.user3 = this.user3;
      elt.users.user4 = this.user4;
      elt.users.user5 = this.user5;
      elt.users.user6 = this.user6;
      elt.users.user7 = this.user7;
      elt.users.user8 = this.user8;
      if (this.buyable) {
        if (this.added) {
          this.$store.commit("removeFromCart", this.item);
        } else {
          window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
          this.$store.commit("addToCart", elt);
        }
      }
      else if (this.isGift) {
        if (this.added) {
          this.$store.commit("removeFromCart", this.item);
        } else {
          window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
          this.$store.commit("addToCart", elt);
        }
      }
    },

    show() {
      this.$emit("show");
    },

    async getPurchaseHistory() {
      let res = await fetch(`/GetPurchaseHistory`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      let response = await res.json();

      responseErrorHandling(response, this.$store, this.$router);

      if (response.error != 0) {
        return;
      }

      this.items = response.result;
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        item.BuyDate = this.formatSQLDateToHuman(item.BuyDate);
        if (item.ActivationDate != null) {
          item.ActivationDate = this.formatSQLDateToHuman(item.ActivationDate);
        }
        if (item.EndDate != null) {
          item.EndDate = this.formatSQLDateToHuman(item.EndDate);
        }
      }
    },

    formatSQLDateToHuman(sqlDate) {
      let year = sqlDate.substring(0, 4);
      let month = sqlDate.substring(5, 7);
      let day = sqlDate.substring(8, 10);
      let date = new Date(year, month - 1, day);
      let humanReadableDate = date.toDateString();
      return humanReadableDate.substring(4, humanReadableDate.length);
    },
  },
};
</script>

<style scoped>
.shop-item-image {
  height: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.itemDescription {
  margin-top: 10px;
  padding: 10px;
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  background: black;
}

.itemDescriptionText {
  padding: 10px;
}

.centerHorizontally {
  width: auto;
  text-align: center;
}

.license {
  height: 100px;
  width: 100%;
  color: black;
  font-family: Arial;
  font-size: 80%;
}

.topRow {
  display: grid;
  grid-template-columns: 50px auto 30px;
}

.secRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.closebutton {
  padding: 2px;
  padding-right: 4px;
  padding-left: 4px;
}

.vendorname {
  text-align: right;
  padding-right: 10px;
}

.checkbox-container {
  display: flex;
  align-items: center; /* Vertically center elements */
}

.centered-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Optional: Set height for vertical centering */
}
</style>
