import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/login.vue";
import Store from "../views/store.vue";
import Signup from "../views/signup.vue";
import Userhome from "../views/userhome.vue";
import Forgotpassword from "../views/forgotpassword.vue";
import Changepassword from "../views/changepassword.vue";
import Redeemcode from "../views/redeemcode.vue";
import Battlereport from "../views/battlereport.vue";
import Directstore from "../views/directstore.vue";
import Purchasecomplete from "../views/purchasecomplete.vue";
import VerifyDevice from "../views/verifyDevice.vue";
import Paypal from "../views/paypal.vue";
import subscriptioncomplete from "../views/subscriptioncomplete.vue";
import subscriptioncancel from "../views/subscriptioncancel.vue";

Vue.use(VueRouter);

// eslint-disable-next-line prettier-vue/prettier
const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/store",
    name: "store",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Store,
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: Forgotpassword,
  },
  {
    path: "/userhome",
    name: "userhome",
    component: Userhome,
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: Changepassword,
  },
  {
    path: "/battlereport",
    name: "battlereport",
    component: Battlereport,
    props: true
  },
  {
    path: "/redeemcode",
    name: "redeemcode",
    component: Redeemcode,
  },
  {
    path: "/directstore",
    name: "directstore",
    component: Directstore,
  },
  {
    path: "/purchaseComplete",
    name: "purchasecomplete",
    component: Purchasecomplete,
  },
  {
    path: "/verifyDevice",
    name: "verifyDevice",
    component: VerifyDevice,
  },
  {
    path: "/paypal",
    name: "paypal",
    component: Paypal,
  },
  {
    path: "/subscriptioncomplete",
    name: "subscriptioncomplete",
    component: subscriptioncomplete,
  },
  {
    path: "/subscriptioncancel",
    name: "subscriptioncancel",
    component: subscriptioncancel,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
