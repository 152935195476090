<template>
  <div>
    <div class="center"> <h1>Purchase Complete!</h1> </div>
    <div class="center">
      <router-link :to="{ name: 'userhome' }"> Back home </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      email: null,
      username: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.email == null) {
        vm.$router.push({ name: "login" });
      } else {
        vm.email = vm.$store.state.email;
      }
    });
  },
};
</script>

<style scoped>
.center {
  text-align: center;
  vertical-align: middle;
  line-height: 12;
  padding: 2rem;
}
</style>
