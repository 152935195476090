<template>
  <div>
    <img :src="`/Images/Warhall_directstore_banner.png`" />
    <!-- Display a payment form -->
    <div class="centerDiv">
      <form id="payment-form" class="centerDiv">
        <div id="payment-element">
          <!--Stripe.js injects the Payment Element-->
        </div>
        <div>
          <button
            @click="buySubscriptionId(12)"
            class="btn shop-item-button"
            type="button"
          >
          Subscribe Now
          </button>
        </div>
      </form>
    </div>
    <div class="centerDiv">
      <router-link :to="{ name: 'store' }"> See more </router-link>
    </div>
  </div>
</template>

<script>
import { responseErrorHandling } from "@/assets/js/common.js";
import Vue from "vue";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.state.appPadding = false;
      if (vm.$store.state.email == null) {
        vm.$router.push({ name: "login" });
      }
    });
  },

  beforeRouteLeave(to, from, next) {
    this.$store.state.appPadding = true;
    next();
  },

  computed: {
    serverAddress() {
      return this.$store.state.serverAddress;
    }
  },

  methods: {

    buySubscriptionId(id) {
      this.initSubscription(id);
    },
    async initSubscription(ID) {
      // Disable the button until we have Stripe set up on the page
      let res = await fetch("/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        //TODO: Should be this only subscription item
        body: JSON.stringify({ items: ID }),
      });
      const { url } = await res.json();
      window.open(url);
    },
  }

};
</script>

<style scoped>
/* Variables */
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  border-radius: 7px;
  padding: 40px;
}

.hidden {
  display: none;
}

#email {
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

.centerDiv {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

#payment-form {
  background-color: whitesmoke;
}
</style>
